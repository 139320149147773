import {useAppContext, useJobContext} from 'contexts';
import {useEffect, useMemo, useState, useCallback} from 'react';
import {getColoredImage} from 'shared/helpers';
import {object, string, number, mixed} from 'yup';
import {DispatchMethod} from 'hooks';
import {FreightToOtherAddress, PickUp} from 'assets';
import {cloneDeep, flatten} from 'lodash';
import {MIMETypes} from 'shared';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {getPhoneRegex} from 'shared/validation/phoneNumber';
import {
    DepotFreightOptions,
    depotListState,
    selectedDepot,
    selectedDepotState,
    setDepotList,
} from 'store/customer/depotSlice';
import {useGetDepotsQuery} from 'store/customer/depotApi';
import {useTheme} from 'styled-components';
import {DateTime} from '@cabinetsbycomputer/datetime';

const depotDispatcFreightOptions = {
    [DepotFreightOptions.PICKUP]: [DispatchMethod.PICKUP],
    [DepotFreightOptions.DELIVERY]: [
        DispatchMethod.FREIGHT_CUSTOM_ADDRESS,
        DispatchMethod.FREIGHT_DELIVERY_ADDRESS,
    ],
    [DepotFreightOptions.PICKUP_AND_DELIVERY]: [
        DispatchMethod.PICKUP,
        DispatchMethod.FREIGHT_CUSTOM_ADDRESS,
        DispatchMethod.FREIGHT_DELIVERY_ADDRESS,
    ],
};

const customValidator = (data, check, message) => {
    const messages = [];

    data.forEach((file) => {
        if (!check(file)) {
            messages.push(message(file));
        }
    });

    return messages;
};

const jobFormSchema = (userProfile, places, attachments) => {
    let countryName = null;
    let states = [];

    if (userProfile.hasOwnProperty('countryName')) {
        countryName = userProfile.countryName;

        const allStates =
            places.hasOwnProperty('Countries') &&
            places.Countries.hasOwnProperty(userProfile.countryName)
                ? places.Countries[userProfile.countryName]
                : [];

        states = allStates.map((state) => state.id);
    }

    const phoneMatch = getPhoneRegex(countryName);

    const checkFreight = (dispatch) =>
        dispatch == DispatchMethod.FREIGHT_CUSTOM_ADDRESS ||
        dispatch == DispatchMethod.FREIGHT_DELIVERY_ADDRESS;

    const requestedDeliveryDateValidation = {
        requestedDeliveryDate: string().required(
            'Please select a requested delivery date.'
        ),
    };

    return object().shape({
        jobName: string().required('Please enter a Job name'),
        contactNumber: string().matches(
            phoneMatch,
            'Please enter a valid phone number'
        ),
        dispatch: number(),
        street: string().when('dispatch', {
            is: checkFreight,
            then: (schema) =>
                schema.required('Please enter your street address'),
        }),
        suburb: string().when('dispatch', {
            is: checkFreight,
            then: (schema) => schema.required('Please enter your suburb'),
        }),
        postcode: number().when('dispatch', {
            is: checkFreight,
            then: (schema) =>
                schema
                    .positive('Please enter a valid Postcode')
                    .typeError('Please enter a valid Postcode')
                    .required('Please enter your Postcode'),
            // use min() max() to define range of postcodes depending upon the country.
        }),
        state: number().when('dispatch', {
            is: checkFreight,
            then: (schema) =>
                schema
                    .required('Please enter your state')
                    .oneOf(states, 'Please enter a valid State'),
            // States stored in static json file in frontend as these are publicly available data and to reduce network overhead
        }),
        files: mixed()
            .nullable()
            .notRequired()
            .test(
                'FILE_NUMBER',
                'Too many files, only 10 files are allowed per job',
                (value) => value.length + attachments <= 10
            )
            .test(
                'FILE_SIZE',
                'The Maximum supported file size is 5 MB.',
                (value, {createError, path}) => {
                    const messages = customValidator(
                        value,
                        (file) => file.size / (1024 * 1024) < 5,
                        (file) =>
                            `"${file.name}" is unable to be uploaded as "${(
                                file.size /
                                (1024 * 1024)
                            ).toFixed(2)} MB" is too large.`
                    );

                    if (messages.length) {
                        messages.splice(
                            0,
                            0,
                            'The Maximum supported file size is 5 MB.'
                        );
                        return createError({
                            path,
                            message: messages.join('<br />'),
                        });
                    }

                    return true;
                }
            )
            .test(
                'FILE_FORMAT',
                'Invalid file format',
                (value, {createError, path}) => {
                    const supportedFormats = flatten(Object.values(MIMETypes));
                    const messages = customValidator(
                        value,
                        (file) => supportedFormats.includes(file.type),
                        (file) =>
                            `"${
                                file.name
                            }" is unable to be uploaded as ".${file.name
                                .split('.')
                                .pop()}" is an unsupported format.`
                    );

                    if (messages.length) {
                        return createError({
                            path,
                            message: messages.join('<br />'),
                        });
                    }

                    return true;
                }
            ),
        ...(userProfile?.allowDeliveryDateRequest
            ? requestedDeliveryDateValidation
            : {}),
    });
};

const initialValueMap = {
    jobName: 'name',
    jobRefCode: 'endCustomerName',
    contactNumber: 'endContactNumber',
    city: 'city',
    description: 'description',
    dispatch: 'dispatchMethod',
    address: 'address',
    suburb: 'suburb',
    postcode: 'postcode',
    state: 'addressState',
    depotId: 'depotId',
    street: 'address',
    requestedDeliveryDate: 'requestedDeliveryDate',
};

const getDispatchMethod = (option) => {
    switch (option) {
        case 'FREIGHT_SPECIFIC_ADDRESS':
            return DispatchMethod.FREIGHT_CUSTOM_ADDRESS;
        case 'FREIGHT_MY_ADDRESS':
            return DispatchMethod.FREIGHT_DELIVERY_ADDRESS;
        default:
            return DispatchMethod.PICKUP;
    }
};

const defaultDeliveryOption = {
    [DepotFreightOptions.PICKUP]: DispatchMethod.PICKUP,
    [DepotFreightOptions.DELIVERY]: DispatchMethod.FREIGHT_DELIVERY_ADDRESS,
    [DepotFreightOptions.PICKUP_AND_DELIVERY]: DispatchMethod.PICKUP,
};

export const useJobForm = (jobId = false) => {
    const theme = useTheme();
    const {userProfile, places} = useAppContext();
    const {job} = useJobContext();
    const dispatch = useAppDispatch();
    const [states, setStates] = useState([]);
    const [initialValues, setInitialValues] = useState({
        jobName: '',
        jobRefCode: '',
        contactNumber: userProfile.phNumber,
        description: '',
        requestedDeliveryDate: '',
        dispatch: getDispatchMethod(userProfile.defaultFreightOption),
        depotId: userProfile.defaultDepotId,
        street: '',
        city: '',
        suburb: '',
        postcode: '',
        state: '',
        client: -1,
        files: [],
        attachments: [],
    });
    const [attachmentsCount, setAttachmentsCount] = useState(0);
    const {data: depotList, isFetching, isLoading} = useGetDepotsQuery();
    const depots = useAppSelector(depotListState, shallowEqual);
    const depotSelected = useAppSelector(selectedDepotState, shallowEqual);

    useEffect(() => {
        if (depotList) {
            dispatch(setDepotList(depotList.depots));
            const depotId = job?.depotId ?? userProfile.defaultDepotId;
            dispatch(selectedDepot(depotId));
        }
    }, [depotList, job]);

    const JobSchema = useMemo(() => {
        const updatedInitialValues = cloneDeep(initialValues);

        const defaultDepot = depots?.find(
            (depot) => depot.id == userProfile.defaultDepotId
        );

        if (defaultDepot) {
            const option =
                depotDispatcFreightOptions[defaultDepot.freight_pickup_option];

            if (!option.includes(updatedInitialValues.dispatch)) {
                const dispatchValue = option.length ? option[0] : 0;
                updatedInitialValues.dispatch = dispatchValue;
            }
        }

        setInitialValues(updatedInitialValues);

        return jobFormSchema(userProfile, places, attachmentsCount);
    }, [userProfile, places, attachmentsCount, depots]);

    const dispatchMethods = useMemo(() => {
        let displayPickup = false;
        let displayDelivery = false;

        if (depotSelected) {
            switch (depotSelected.freight_pickup_option) {
                case DepotFreightOptions.PICKUP:
                    displayPickup = true;
                    break;
                case DepotFreightOptions.DELIVERY:
                    displayDelivery = true;
                    break;
                case DepotFreightOptions.PICKUP_AND_DELIVERY:
                    displayPickup = true;
                    displayDelivery = true;
                    break;
            }
        } else {
            displayPickup = true;
            displayDelivery = true;
        }

        const PICKUP = [
            {
                value: DispatchMethod.PICKUP,
                id: 'dispatch-pickup',
                label: 'Pickup',
                selectedImage: getColoredImage(
                    PickUp,
                    '#204380',
                    theme.colors.primary.main
                ),
                image: getColoredImage(
                    PickUp,
                    '#204380',
                    theme.colors.secondary.main
                ),
                display: userProfile.manufacturerPickupAvailable,
                selectable: displayPickup,
            },
        ];

        const DELIVERY = [
            {
                value: DispatchMethod.FREIGHT_CUSTOM_ADDRESS,
                id: 'dispatch-specific',
                label: 'Freight to address',
                selectedImage: getColoredImage(
                    FreightToOtherAddress,
                    '#204380',
                    theme.colors.primary.main
                ),
                image: getColoredImage(
                    FreightToOtherAddress,
                    '#204380',
                    theme.colors.secondary.main
                ),
                display: userProfile.manufacturerFreightAvailable,
                selectable: displayDelivery,
            },
        ];

        return [...PICKUP, ...DELIVERY];
    }, [userProfile, depotSelected]);

    const selectDepot = (setFieldValue, depotId) => {
        dispatch(selectedDepot(depotId));
        const selected = depots.find((depot) => depot.id === depotId);
        const dispatchValue =
            defaultDeliveryOption[selected.freight_pickup_option];

        setFieldValue('depotId', depotId);
        setFieldValue('dispatch', dispatchValue);
    };

    const updateAttachmentsCount = useCallback((count) => {
        setAttachmentsCount(count);
    }, []);

    useEffect(() => {
        if (jobId) {
            const updatedValues = cloneDeep(initialValues);

            // disable these here as keys are knows list of data not user input
            Object.keys(initialValueMap).forEach((key) => {
                // eslint-disable-next-line security/detect-object-injection
                if (job.hasOwnProperty(initialValueMap[key])) {
                    if (key === 'requestedDeliveryDate') {
                        // eslint-disable-next-line security/detect-object-injection
                        const date = job[initialValueMap[key]];

                        if (date != null) {
                            // eslint-disable-next-line security/detect-object-injection
                            updatedValues[key] = DateTime.parse(date)
                                .format()
                                .toString();
                        }
                    } else {
                        // eslint-disable-next-line security/detect-object-injection
                        updatedValues[key] =
                            // eslint-disable-next-line security/detect-object-injection
                            job[initialValueMap[key]] === null
                                ? ''
                                : // eslint-disable-next-line security/detect-object-injection
                                  job[initialValueMap[key]];
                    }
                }
            });

            if (
                job.hasOwnProperty('dispatchMethod') &&
                job.dispatchMethod === DispatchMethod.FREIGHT_TO_CLIENT_ADDRESS
            ) {
                // For existing jobs with freight to client's address - fallback to freight to address
                updatedValues.dispatch = DispatchMethod.FREIGHT_CUSTOM_ADDRESS;
            }

            let attachments = 0;

            if (job.attachments && Array.isArray(job.attachments)) {
                attachments = job.attachments.length;
            }

            setAttachmentsCount(attachments);
            setInitialValues(updatedValues);
        }
    }, [jobId, job]);

    useEffect(() => {
        if (userProfile) {
            const allStates =
                places.hasOwnProperty('Countries') &&
                places.Countries.hasOwnProperty(userProfile.countryName)
                    ? places.Countries[userProfile.countryName]
                    : [];

            setStates(allStates);
        }
    }, [userProfile, places]);

    return {
        states,
        dispatchMethods,
        userIsPremium:
            userProfile.hasOwnProperty('premium') && userProfile.premium == 1,
        allowFileUpload: userProfile?.allowFileUpload,
        allowDeliveryDateRequest: userProfile?.allowDeliveryDateRequest,
        minimumLeadTime: userProfile?.minimumLeadTime,
        initialValues,
        JobSchema,
        loading: isFetching || isLoading,
        updateAttachmentsCount,
        selectDepot,
    };
};
